import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./style.scss";
import Select from "react-select";
import environment from "../../environment";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import QRCodeStyling from "styled-qr-code";
import methodModel from "../../methods/methods";

const QrCodeListing = () => {
  const { slug } = useParams();
  const user = useSelector((state) => state.user);
  const [data, setdata] = useState([]);
  const [downloadimg, setdownloadimg] = useState(null);
  const ActivePlan = useSelector((state) => state.activePlan);
  const MultiRef = useRef();
  const [QRCODES, setQrCodes] = useState([]);
  const canvasRef2 = useRef();
  const [DownloadItem, setDownloadItem] = useState({ data: {}, index: 0 });
  const [filter, setfilter] = useState({
    page: 1,
    count: 10,
    user_id: user?.id,
    search: "",
    sortBy: "updatedAt desc",
    type: "",
    slug: "",
    campaignName: "",
    medium: "",
  });
  let host = document.location.host;
  let RedirectURL = environment?.liveURL;
  // if (host?.includes("https://qrcode.jcsoftwaresolution.in/")) {
  //   RedirectURL = "https://qrcode.jcsoftwaresolution.in/";
  // } else {
  //   RedirectURL = "http://66.179.251.9:8056/";
  // }

  const [total, settotal] = useState();
  const history = useNavigate();
  const [dataCheck, setdataCheck] = useState([]);
  const [selectFormat, setSelectedFormat] = useState("");
  const [types, settypes] = useState([]);
  const token = localStorage.getItem("token");

  const canvasRef = useRef([]);
  const [detail, setdetail] = useState();
  const [activeGrid, setActiveGrid] = useState(true);

  const [rename, setrename] = useState({
    active: false,
  });
  const [folder, setfolder] = useState();
  const [selectedFolder, setselectedFolder] = useState({ id: "", payload: {} });
  const [dailyScans, setdailyScans] = useState({ unique: 0, total: 0 });
  const [weeklyScans, setweeklyScans] = useState({ unique: 0, total: 0 });
  const [monthlyScans, setmonthlyScans] = useState({ unique: 0, total: 0 });
  const [campaignAnalytics, setcampaignAnalytics] = useState();
  const [EditData, setEditData] = useState({
    flyer: "",
    printRun: "",
    campaignStart: "",
    campaignEnd: "",
  });
  const [Expiry, setExpiry] = useState({});
  const [Downloadimageindex, setDownloadimageindex] = useState(null);
  const [Type, setType] = useState([]);
  const [Tableformat, setTableformat] = useState("");

  const GetType = () => {
    ApiClient.get("qrtype/all").then((res) => {
      setType(res.data);
    });
  };

  // useEffect(()=>{
  //   setExpiry({...Expiry,numberOfScans:Expiry?.totalScans})
  //   },[])

  console.log(downloadimg,'downloadimgdownloadimg')

  useEffect(() => {
    GetType();
  }, []);

  const FindType = (data) => {
    console.log(data,"popopopo")
    let value = "";
    if (data == "url") {
      data = "urls";
    }
    value = Type && Type.filter((itm) => itm?.slug == "smart-url" ? itm?.slug == data : itm?.slug == data?.replace("-", " "));
    return value[0]?.type ? value[0]?.type : "--";
  };

  // For Update Campaign Data
  const UpdateEditdata = (e) => {
    e.preventDefault();
    let payload = { ...EditData };
    delete payload?.updatedAt;
    delete payload?.updatedBy;
    delete payload?.createdAt;
    delete payload?.deletedAt;
    delete payload?.deletedBy;
    delete payload?.addedBy;
    loader(true);
    ApiClient.put("qrcode/download", payload).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getData({ sortBy: "updatedAt desc" });
        document.getElementById("btnclose").click();
        loader(false);
      }
    });
  };

  const HandleEditchange = (e) => {
    const value = e.target.value;
    const data = EditData;
    data["setting"][e.target.name] = value;
    setEditData({ ...data });
  };
  useEffect(() => {
    getData();
    getAllTypes();
    getFolders();

    // Analytics API's
    if (user?.id) {
      getDailyScans();
      getWeeklyScans();
      getMonthlyScans();
      getcampaignAnalytics();
    }
  }, []);
  const getDetail = (id) => {
    loader(true);
    ApiClient.get(`qrcode/download?slug=${slug}&id=${id}`).then((res) => {
      if (res.success) {
        setdetail(res?.data);
        setduplicateQrCode(`Copy of ${res?.data?.setting?.qrCodeName}`);
        // setrename({
        //   active: false,
        //   name: res?.data?.setting?.qrCodeName || "",
        // });
        if (res?.data?.staticQrCode) {
          setform({ ...res?.data?.design, value: res?.data?.qrCode_data });
        } else {
          setform({
            ...res?.data?.design,
            value: `${environment?.liveURL}${res?.data?.slug}`,
          });
        }
        setframeForm(
          res?.data?.frames
            ? res?.data?.frames
            : {
                width: "250",
                height: "250",
                frame: "",
                qrFrameColor: "#030202",
                qrFrameColorGradient1: "",
                qrFrameColorGradient2: "",
                text: "SCAN ME",
                fontStyle: "",
                textColor: "#3c61e4",
              }
        );
      }
      loader(false);
    });
  };
  const handleRename = (e) => {
    e.preventDefault();
    let payload = {
      id: rename?.id,
      setting: { ...rename?.setting },
    };
    ApiClient.put(`qrcode/download`, payload).then((res) => {
      if (res.success) {
        toast.success(res?.message);
        getData();
        // getDetail()
        setrename({ active: false });
      }
    });
  };

  const getDailyScans = () => {
    ApiClient.get(`scanAnalytics?type=daily&userId=${user?.id}`).then((res) => {
      if (res.success) {
        setdailyScans({
          unique: res?.data?.[0]?.uniqueScan || 0,
          total: res?.data?.[0]?.totalCount || 0,
        });
      }
    });
  };

  const getWeeklyScans = () => {
    ApiClient.get(`scanAnalytics?type=week&userId=${user?.id}`).then((res) => {
      if (res.success) {
        setweeklyScans({
          unique: res?.data?.[0]?.uniqueScan || 0,
          total: res?.data?.[0]?.totalCount || 0,
        });
      }
    });
  };

  const getMonthlyScans = () => {
    ApiClient.get(`scanAnalytics?type=monthly&userId=${user?.id}`).then(
      (res) => {
        if (res.success) {
          setmonthlyScans({
            unique: res?.data?.[0]?.uniqueScan || 0,
            total: res?.data?.[0]?.totalCount || 0,
          });
        }
      }
    );
  };

  const getcampaignAnalytics = () => {
    ApiClient.get(`qrAnalytics?user_id=${user?.id}`).then((res) => {
      if (res.success) {
        setcampaignAnalytics(res?.data);
      }
    });
  };

  const getFolders = () => {
    ApiClient.get(`folder/all?user_id=${user?.id}`).then((res) => {
      if (res.success) {
        setfolder(res?.data?.data);
      }
    });
  };

  const getAllTypes = () => {
    ApiClient.get(`qrtype/all?status=active`).then((res) => {
      if (res.success) {
        settypes(
          res?.data?.map((item) => {
            return { value: item?.slug, label: item?.type };
          })
        );
      }
    });
  };

  const getData = (p = {}) => {
    let QRcodes = [];
    let filters = { ...filter, ...p };
    loader(true);
    ApiClient.get(`qrcode/download/all`, filters).then((res) => {
      if (res.success) {
        setdata(res?.data?.data);
        settotal(res?.data?.total);
        res?.data?.data?.map(async (itm, index) => {
          const logoImage = itm?.design?.logoimg
          ? `${environment.api}images/logo/${itm?.design?.logoimg}` : itm?.design?.sluglogoimg ? `${environment.api}images/qrcodetypes/${itm?.design?.sluglogoimg}`
          : ""

          const qrCode = new QRCodeStyling({
            width: 500,
            height: 500,
            type: "svg",
            margin: 5,
            data: itm?.dynamicQrcode
              ? `${RedirectURL}${itm?.slug}`
              : itm?.qrCode_data || "https://qrcode-bubble.com",
            image:logoImage,
            crossOrigin: "anonymous",
            qrOptions: {
              typeNumber: 0,
              mode: "Byte",
              errorCorrectionLevel: itm?.design?.errorCorrection,
            },
            dotsOptions: {
              color: itm?.design?.qrBodyPatternColor,
              type: itm?.design?.qrBodyPattern,
              gradient:
                itm?.design?.qrBodyPatternGradientColor1 ||
                itm?.design?.qrBodyPatternGradientColor2
                  ? {
                      type: itm?.design?.qrBodyPatternGradientColorType,
                      colorStops: [
                        {
                          offset: 0,
                          color:
                            itm?.design?.qrBodyPatternGradientColor1 ||
                            "#030202",
                        },
                        {
                          offset: 1,
                          color:
                            itm?.design?.qrBodyPatternGradientColor2 ||
                            "#030202",
                        },
                      ],
                    }
                  : "",
            },
            imageOptions: {
              crossOrigin: "anonymous",
              hideBackgroundDots: itm?.design.logotoggle,
              margin: 0,
              imageSize: `0.${itm?.design?.logosize}`,
            },

            cornersSquareOptions: {
              type: itm?.design?.qrOuterEye,
              color: itm?.design?.qrOuterEyeColor,
              gradient:
                itm?.design?.qrOuterEyeColorGradient1 ||
                itm?.design?.qrOuterEyeColorGradient2
                  ? {
                      type: itm?.design?.qrOuterEyeColorGradientType,
                      colorStops: [
                        {
                          offset: 0,
                          color:
                            itm?.design?.qrOuterEyeColorGradient1 || "#030202",
                        },
                        {
                          offset: 1,
                          color:
                            itm?.design?.qrOuterEyeColorGradient2 || "#030202",
                        },
                      ],
                    }
                  : "",
            },
            cornersDotOptions: {
              type: itm?.design?.qrInnerEye,
              color: itm?.design?.qrInnerEyeColor,
              gradient:
                itm?.design?.qrInnerEyeColorGradient1 ||
                itm?.design?.qrInnerEyeColorGradient2
                  ? {
                      type: itm?.design?.qrInnerEyeColorGradientType,
                      colorStops: [
                        {
                          offset: 0,
                          color:
                            itm?.design?.qrInnerEyeColorGradient1 || "#030202",
                        },
                        {
                          offset: 1,
                          color:
                            itm?.design?.qrInnerEyeColorGradient2 || "#030202",
                        },
                      ],
                    }
                  : "",
            },
            backgroundOptions: {
              color: itm?.design?.backgroundGradientColor1
                ? itm?.design?.backgroundGradientColor1
                : itm?.design?.backgroundColor || "#ffffff",
              gradient:
                itm?.design?.backgroundGradientColor1 ||
                itm?.design?.backgroundGradientColor2
                  ? {
                      type: itm?.design?.backgroundGradientColorType,
                      colorStops: [
                        {
                          offset: 0,
                          color:
                            itm?.design?.backgroundGradientColor1 || "#FFFFFF",
                        },
                        {
                          offset: 1,
                          color:
                            itm?.design?.backgroundGradientColor2 || "#FFFFFF",
                        },
                      ],
                    }
                  : "",
            },
          });
          QRcodes.push(qrCode);
          setQrCodes(QRcodes);
          // document.getElementById(`Multicanvas${index}`).innerHTML = "";
          // qrCode.append(document.getElementById(`Multicanvas${index}`));
          // ListQRCodes.push(qrCode);
        });
      }
      loader(false);
    });
  };

  const handlePagination = (e) => {
    setfilter({ ...filter, page: e });
    getData({ page: e });
  };

  const handleStatus = (id, type) => {
    if (type == "delete") {
      if (window.confirm(`Do you really want to delete this qr code ?`)) {
        ApiClient.delete(`qrcode/download?id=${id}`).then((res) => {
          if (res.success) {
            toast.success(res.message);
            getcampaignAnalytics();
            getData();
          }
        });
      }
    } else if (type == "edit") {
      history(`/app/qrcode/edit/${id}`);
    } else {
      if (id?.staticQrcode) {
        return;
      } else {
        let payload = {
          id: id?.id,
          status: id?.status == "pause" ? "active" : "pause",
        };
        if (window.confirm("Do you really want to change this status")) {
          ApiClient.put(`updateStatus`, { data: [payload] }).then((res) => {
            if (res.success) {
              toast.success(res?.message);
              getcampaignAnalytics();
              setdataCheck([]);
              getData();
              getAllTypes();
            }
          });
        }
      }
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setfilter({ ...filter, [e.target.name]: value });
    getData({ [e.target.name]: value });
  };

  const handleFilters = (value, type) => {
    if (type == "sortBy") {
      setfilter({ ...filter, sortBy: value });
      getData({ sortBy: value });
    } else {
      getData({
        type: filter?.type?.value,
        slug: filter?.slug,
        campaignName: filter?.campaignName,
        medium: filter?.medium,
      });
    }
  };

  const handleResetFilters = () => {
    setfilter({
      ...filter,
      type: "",
      slug: "",
      campaignName: "",
      medium: "",
    });
    getData({
      ...filter,
      type: "",
      slug: "",
      campaignName: "",
      medium: "",
    });
  };

  const downlaodQrCodes = () => {
    if (dataCheck?.length == 0) {
      toast.error("Select QR code for download");
      return;
    }
    let array = [];
    data &&
      data.find((item) => {
        if (dataCheck.includes(item?.id)) {
          array.push(item?.image);
        }
      });
    array &&
      array.map((item, index) => {
        if (selectFormat == "svg") {
          var img = new Image();
          img.onload = function () {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.fillStyle = "black";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0);
            var svg =
              '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' +
              img.width +
              " " +
              img.height +
              '" width="' +
              img.width +
              '" height="' +
              img.height +
              '"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml"><img src="' +
              canvas.toDataURL("image/png") +
              '"/></div></foreignObject></svg>';

            // Download the SVG file
            var blob = new Blob([svg], { type: "image/svg+xml" });
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "QrCode.svg";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          };
          img.src = item;
        } else {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          var img = new Image();
          img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            // Convert the image to a different format (e.g., PNG)
            if (selectFormat === "png") {
              var newImage = canvas.toDataURL("image/png", 1);
            } else {
              var newImage = canvas.toDataURL("image/jpeg", 1);
            }
            // var newImage = canvas.toDataURL("image/jpeg");

            var a = document.createElement("a");
            a.href = newImage;
            if (selectFormat === "png") {
              a.download = `QrCode.png`;
            } else if (selectFormat === "jpeg") {
              a.download = `QrCode.jpeg`;
            }
            // a.download = `QrCode.jpeg`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          };
          img.src = item;
        }
      });
    document.getElementById("CloseMultiFormatModal").click();
  };

  // Checks
  const handleAllCheck = (check) => {
    if (check) {
      let array = [];
      data &&
        data.map((item) => {
          array.push(item?.id);
        });
      setdataCheck(array);
    } else {
      setdataCheck([]);
    }
  };
  const checkAllChecks = () => {
    let value = true;
    data &&
      data.find((item) => {
        if (dataCheck.includes(item?.id)) {
          return;
        } else {
          value = false;
        }
      });
    return value;
  };
  const handleCheck = (check, item) => {
    let array = dataCheck;
    if (check) {
      array.push(item?.id);
    } else {
      array = dataCheck.filter((itm) => itm != item?.id);
    }
    setdataCheck([...array]);
  };

  // Multiple Delete
  const multipleDelete = () => {
    if (dataCheck?.length != 0) {
      if (window.confirm("Do you really want to delete this qr codes ?")) {
        axios
          .delete(`${environment.api}qrcode/download/multiple`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              ids: dataCheck,
            },
          })
          .then((res) => {
            toast.success(res?.data?.message);
            getData();
            getcampaignAnalytics();
          });
      }
    } else {
      toast.error("Qr Code Not Selected");
      return;
    }
  };

  const pausedQrCode = (e) => {
    e.preventDefault();
    let settingDetail = data?.filter(
      (item) => item?.id == dataCheck.filter((itm) => itm == item?.id)
    );
    let dynamicOnly = true;
    settingDetail.filter((item) => {
      if (item?.staticQrcode) dynamicOnly = false;
    });
    if (!dynamicOnly) {
      toast.error("You can't change static qr code static.");
      return;
    }
    if (dataCheck?.length != 0) {
      let payload =
        dataCheck &&
        dataCheck.map((item) => {
          return { id: item, status: "pause" };
        });
      if (window.confirm("Do you really want to change this status")) {
        ApiClient.put(`updateStatus`, { data: payload }).then((res) => {
          if (res.success) {
            toast.success(res?.message);
            setdataCheck([]);
            getData();
            getcampaignAnalytics();
            getAllTypes();
          }
        });
      }
    } else {
      toast.error("Qr Code Not Selected");
      return;
    }
  };

  const activeQrCode = (e) => {
    e.preventDefault();
    let settingDetail = data?.filter(
      (item) => item?.id == dataCheck.filter((itm) => itm == item?.id)
    );
    let dynamicOnly = true;
    settingDetail.filter((item) => {
      if (item?.staticQrcode) dynamicOnly = false;
    });
    if (!dynamicOnly) {
      toast.error("You can't change static qr code static.");
      return;
    }
    if (dataCheck?.length != 0) {
      let payload =
        dataCheck &&
        dataCheck.map((item) => {
          return { id: item, status: "active" };
        });
      if (window.confirm("Do you really want to change this status")) {
        ApiClient.put(`updateStatus`, { data: payload }).then((res) => {
          if (res.success) {
            toast.success(res?.message);
            setdataCheck([]);
            getData();
            getcampaignAnalytics();
            getAllTypes();
          }
        });
      }
    } else {
      toast.error("Qr Code Not Selected");
      return;
    }
  };

  const totalScansCount = (data) => {
    let value = 0;
    if (data?.length != 0) {
      data?.map((item) => {
        value += item?.count;
      });
      return value;
    } else {
      return value;
    }
  };

  // Move Folder
  const moveFolder = (type, id = "") => {
    if (type == "modal" && id) {
      setselectedFolder({ id: "", payload: {} });
      setdataCheck([id]);
      document.getElementById("OpenMoveFolderModal").click();
      return;
    }
    let settingDetail = data?.filter(
      (item) => item?.id == dataCheck.filter((itm) => itm == item?.id)
    );
    let dynamicOnly = true;
    settingDetail.filter((item) => {
      if (item?.staticQrcode) dynamicOnly = false;
    });
    // if (!dynamicOnly) {
    //     toast.error("You can't move folder with static qr code.")
    //     return
    // }
    if (type == "modal") {
      if (dataCheck?.length != 0) {
        setselectedFolder({ id: "", payload: {} });
        document.getElementById("OpenMoveFolderModal").click();
      } else {
        toast.error("Qr Code Not Selected");
        return;
      }
    } else {
      ApiClient.put(`updateMultipleFolder`, {
        data: selectedFolder?.payload,
      }).then((res) => {
        if (res.success) {
          getData();
          setselectedFolder({ id: "", payload: {} });
          setdataCheck([]);
          document.getElementById("CloseMoveFolderModal").click();
          toast.success(res?.message);
        }
      });
    }
  };

  const handleFolderSelect = (e) => {
    let folderDetail = folder.find((item) => item?._id == e);
    let settingDetail = data?.filter(
      (item) => item?.id == dataCheck.filter((itm) => itm == item?.id)
    );
    let values = settingDetail?.map((item) => {
      return {
        id: item?.id,
        setting: {
          ...item?.setting,
          folderName: folderDetail?.name,
          folderId: e,
        },
      };
    });
    setselectedFolder({ ...selectedFolder, id: e, payload: values });
  };

  const handleRedirectURL = (url) => {
    let redirectUrl = ''
    if (!url.includes("http" || "https")) {
      redirectUrl =`https://${url}`
    } else {
      redirectUrl = url
    }
    window.open(redirectUrl);
  };

  const TableDownload = () => {
    document.getElementById("Tablemodal").click();
  };

  const ConvertDiv = () => {
    if (!canvasRef2.current) {
      return;
    }

    if (selectFormat == "png") {
      toPng(canvasRef2.current, {
        cacheBust: true,
        quality: 1, // Set this value to 1 for the highest quality
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "my-image.png";

          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
        });
    }

    if (selectFormat == "jpeg") {
      toJpeg(canvasRef2.current, {
        cacheBust: true,
        quality: 1, // Set this value to 1 for the highest quality
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "my-image.jpeg";

          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
        });
    }

    if (selectFormat == "svg") {
      ConvertQRtoSVG();
    }
    document.getElementById("CloseFormatModal").click();

    // if (canvasRef2.current) {
    //   html2canvas(canvasRef2.current,{
    //     // width:1000,
    //     // height:1000
    //     scale:2
    //   }).then((canvas) => {
    //     const image = new Image();
    //     image.src = canvas.toDataURL("image/png", 1.0);

    //     // if (selectFormat == 'png') {
    //     // image.src = canvas.toDataURL("image/png", 1.0);

    //     var a = document.createElement("a");
    //     a.href = image.src;
    //     a.download = `QrCodeUpdated.png`;

    //     // a.download = `QrCode.jpeg`;
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    //     // } else {
    //     //     image.src = canvas.toDataURL("image/jpeg", 1.0);
    //     // }
    //     // saveQrCode(image.src);
    //   });
    // }
  };

  const handleDownloadQrCode = () => {
    // Check if Tableformat is not 'svg'
    if (Tableformat !== "svg") {
        // Create a canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        const scaleFactor = 10; // Increase scaleFactor for better quality
        const quality = 1.0; // Adjust quality for better image quality

        // Load image
        img.onload = function () {
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert the image to the desired format
            const mimeType = Tableformat === "png" ? "image/png" : "image/jpeg";
            const newImage = canvas.toDataURL(mimeType, quality);
            console.log(newImage,"sghdgf")
            return

            // Create a link to download the image
            const a = document.createElement("a");
            a.href = newImage;
            a.download = `QrCode.${Tableformat}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };

        // Set source of image
        img.src = downloadimg;
        document.getElementById("CloseTableFormatModal").click();
    } else {
        // Convert QR to SVG
        ConvertQRtoSVG();
        document.getElementById("CloseTableFormatModal").click();
    }
};


  const ConvertQRtoSVG = () => {
    var img = new Image();
    img.onload = function () {
      var canvas = document.createElement("canvas");

      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");

      // Set background color to white
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the image on the canvas
      ctx.drawImage(img, 2, 2);

      // Convert canvas content to SVG
      var svg =
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' +
        img.width +
        " " +
        img.height +
        '" width="' +
        img.width +
        '" height="' +
        img.height +
        '"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml"><img src="' +
        canvas.toDataURL("image/png") +
        '"/></div></foreignObject></svg>';

      // Download the SVG file
      var blob = new Blob([svg], { type: "image/svg+xml" });
      var url = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "image.svg";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };
    img.src = downloadimg;
    document.getElementById("CloseTableFormatModal").click();
  };
  const handlePausedQrCode = (id) => {
    let payload = [{ id: id, status: "pause" }];
    if (window.confirm("Do you really want to change this status")) {
      ApiClient.put(`updateStatus`, { data: payload }).then((res) => {
        if (res.success) {
          toast.success(res?.message);
          getcampaignAnalytics();
          getData();
          getAllTypes();
        }
      });
    }
  };

  const handleActivateQrCode = (id) => {
    let payload = [{ id: id, status: "active" }];
    if (window.confirm("Do you really want to change this status")) {
      ApiClient.put(`updateStatus`, { data: payload }).then((res) => {
        if (res.success) {
          toast.success(res?.message);
          getcampaignAnalytics();
          getData();
          getAllTypes();
        }
      });
    }
  };

  const handleMoveFolder = (type) => {
    if (type == "modal") {
      document.getElementById("OpenMoveFolderModal").click();
    }
  };

  const DownloadQR = (index, item) => {
    document.getElementById("OpenFormatModel").click();
    setDownloadimageindex(index);
    setDownloadItem({ data: item, index: index });
  };

  const convertHTMLToImage = (e, index) => {
    // if (DownloadItem?.data?.design?.isFrame) {
    //   return ConvertDiv();
    // }
    // if (selectFormat == "svg") {
    //   QRCODES[DownloadItem?.index].download({
    //     name: "QRCode",
    //     extension: "svg",
    //   });
    // }
    // if (selectFormat == "png") {
    //   QRCODES[DownloadItem?.index].download({
    //     name: "QRCode",
    //     extension: "png",
    //   });
    // }
    // if (selectFormat == "jpeg") {
    //   QRCODES[DownloadItem?.index].download({
    //     name: "QRCode",
    //     extension: "jpeg",
    //   });
    // }
    // document.getElementById("CloseFormatModal").click();

    e.preventDefault();

    if (selectFormat == "svg") {
      ConvertQRtoSVG();
    } else {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var img = new Image();
      
      // You can adjust this value for higher quality scaling
      var scaleFactor = 3; // Increased to 3 for better clarity
      
      img.onload = function () {
        // Set canvas size based on the image and scale factor
        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;
        
        // Draw the image with scaling on the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        
        // Convert the image to the selected format (PNG/JPEG)
        var newImage;
        if (selectFormat === "png") {
          newImage = canvas.toDataURL("image/png", 1); // PNG - Lossless
        } else if (selectFormat === "jpeg") {
          newImage = canvas.toDataURL("image/jpeg", 0.95); // JPEG - Higher quality (0.95)
        }
        
        // Create download link for the image
        var a = document.createElement("a");
        a.href = newImage;
        if (selectFormat === "png") {
          a.download = `QrCode.png`;
        } else if (selectFormat === "jpeg") {
          a.download = `QrCode.jpeg`;
        }
  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      
      // Ensure the image source is valid before setting
      if (downloadimg) {
        img.src = downloadimg;
      } else {
        console.error('Image source is not valid');
      }
  
      document.getElementById("CloseMultiFormatModal").click();
    }
  };

  const DownLoadMutliQR = () => {
    data?.map((item, index) => {
      if (dataCheck.includes(item?._id)) {
        if (!item?.design?.isFrame) {
          if (selectFormat == "svg") {
            QRCODES[index].download({
              name: "QRCode",
              extension: "svg",
            });
          }
          if (selectFormat == "png") {
            QRCODES[index].download({
              name: "QRCode",
              extension: "png",
            });
          }
          if (selectFormat == "jpeg") {
            QRCODES[index].download({
              name: "QRCode",
              extension: "jpeg",
            });
          }
          // QRCODES[index].download({ name: "QRCODE", extension: "png" })
        } else if (item?.design?.isFrame) {
          if (selectFormat == "svg") {
            var img = new Image();

            img.onload = function () {
              var canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              var ctx = canvas.getContext("2d");

              // Set background color to white
              ctx.fillStyle = "black";
              ctx.fillRect(0, 0, canvas.width, canvas.height);

              // Draw the image on the canvas
              ctx.drawImage(img, 0, 0);

              // Convert canvas content to SVG
              var svg =
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' +
                img.width +
                " " +
                img.height +
                '" width="' +
                img.width +
                '" height="' +
                img.height +
                '"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml"><img src="' +
                canvas.toDataURL("image/png") +
                '"/></div></foreignObject></svg>';

              // Download the SVG file
              var blob = new Blob([svg], { type: "image/svg+xml" });
              var url = URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = url;
              a.download = "QrCode.svg";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              URL.revokeObjectURL(url);
            };
            img.src = item?.image;
          } else {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            var img = new Image();
            var scaleFactor = 2;
            img.onload = function () {
              canvas.width = img.width * scaleFactor;
              canvas.height = img.height * scaleFactor;
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

              // Convert the image to a different format (e.g., PNG)
              if (selectFormat === "png") {
                var newImage = canvas.toDataURL("image/png", 1.0);
              } else {
                var newImage = canvas.toDataURL("image/jpeg", 1.0);
              }
              // var newImage = canvas.toDataURL("image/jpeg");

              var a = document.createElement("a");
              a.href = newImage;
              if (selectFormat === "png") {
                a.download = `QrCode.png`;
              } else if (selectFormat === "jpeg") {
                a.download = `QrCode.jpeg`;
              }
              // a.download = `QrCode.jpeg`;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            };
            img.src = item?.image;
            setdataCheck([]);
          }

          document.getElementById("CloseMultiFormatModal").click();
        }
      }
    });
  };


  const UpdateExpiry = (e) => {
    e.preventDefault();
    
   if(Expiry?.setting?.numberOfScansStatus || Expiry?.setting?.dateRangeStatus){
    if(!Expiry?.setting?.expiryURL){
      toast.error('Please add expiry url first..')
        return
    }}
    let payload = { ...Expiry ,"totalScans":Expiry?.setting?.numberOfScans};
    delete payload?.updatedAt;
    delete payload?.updatedBy;
    delete payload?.createdAt;
    delete payload?.deletedAt;
    delete payload?.deletedBy;
    delete payload?.addedBy;
    loader(true);
    delete payload?.updatedAt;
    delete payload?.updatedBy;
    delete payload?.createdAt;
    delete payload?.deletedAt;
    delete payload?.deletedBy;
    delete payload?.addedBy;
    ApiClient.put("qrcode/download", payload).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getData({ sortBy: "updatedAt desc" });
        document.getElementById("CloseExpiry").click();
      }
      loader(false);
    });
  };
  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="list_box3">
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="list1">
                  <h1>UNIQUE SCANS</h1>
                  <div className="box_color">
                    <div className="box_col">
                      <h3>{dailyScans?.unique}</h3>
                      <p>Today</p>
                    </div>
                    <div className="box_col">
                      <h3>{weeklyScans?.unique}</h3>
                      <p>7 days</p>
                    </div>
                    <div className="box_col">
                      <h3>{monthlyScans?.unique}</h3>
                      <p>{moment(new Date()).format("MMM YYYY")}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <div className="list1">
                  <h1>TOTAL SCANS</h1>
                  <div className="box_color box_color2">
                    <div className="box_col">
                      <h3>{dailyScans?.total}</h3>
                      <p>Today</p>
                    </div>
                    <div className="box_col">
                      <h3>{weeklyScans?.total}</h3>
                      <p>7 days</p>
                    </div>
                    <div className="box_col">
                      <h3>{monthlyScans?.total}</h3>
                      <p>{moment(new Date()).format("MMM YYYY")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="list1">
                  <h1>QR CODES</h1>
                  <div className="box_color box_color3">
                    <div className="box_col">
                      <h3>{campaignAnalytics?.get_active_count || 0}</h3>
                      <p>Active</p>
                    </div>
                    <div className="box_col">
                      <h3>{campaignAnalytics?.get_paused_count || 0}</h3>
                      <p>Paused</p>
                    </div>
                    <div className="box_col">
                      <h3>{campaignAnalytics?.get_stastic_count || 0}</h3>
                      <p>Static</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="active_h1">
            {/* <span onClick={e => handleStatus('active')} className={`mx-2 ${detail?.staticQrcode ? 'static' : detail?.status == 'pause' ? 'paused pointer' : 'status pointer'} text-capitalize`}>
                 {detail?.staticQrcode ? 'Static' : detail?.status == 'pause' ? 'Paused' : 'Active'}
             </span> */}
            {rename?.active ? (
              <form className="d-flex" onSubmit={handleRename}>
                <input
                  type="text"
                  value={rename?.setting?.qrCodeName}
                  onChange={(e) =>
                    setrename({
                      ...rename,
                      setting: {
                        ...rename.setting,
                        qrCodeName: e.target.value,
                      },
                    })
                  }
                  className="form-control ms-2"
                  placeholder="Campaign Name"
                  required
                />
                <button
                  className="btn btn-white border ms-2"
                  onClick={() => setrename({ active: false })}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary ms-2">
                  Save
                </button>
              </form>
            ) : (
              <h1 className="name_heading">
                {detail?.setting?.qrCodeName || ""}{" "}
              </h1>
            )}
          </div>
          <div className="second_div mb-3 my-2">
            <div className="trash_div">
              <div className="search_trash_div">
                {/* <form onSubmit={(e) => handleSearch(e)}> */}
                <input
                  type="search"
                  name="search"
                  value={filter?.search}
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  className="form-control"
                  placeholder="Search..."
                />
                <i className="fa fa-search search_pos"></i>

                {/* <button  className="d-none"></button> */}
                {/* </form> */}
              </div>
              <div className="dropdown_flex">
                <div className="select_input ml-md-2">
                  <p className="type_text">Sort By:</p>
                  <select
                    value={filter?.sortBy}
                    onChange={(e) => handleFilters(e.target.value, "sortBy")}
                    className="form-select select_url"
                  >
                    <option
                      className="form_select_options"
                      value="updatedAt desc"
                    >
                      Latest
                    </option>
                    <option
                      className="form_select_options"
                      value="updatedAt asc"
                    >
                      Oldest
                    </option>
                  </select>
                </div>
                <div className="dropdown drop_it">
                  <button
                    className="btn btn-light light_btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Action
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={(e) => {
                          dataCheck.length == 0
                            ? toast.error("Select QR code for download")
                            : document
                                .getElementById("OpenMultiFormatModel")
                                .click();
                        }}
                      >
                        <span className="material-icons me-2 text-primary fs-4">
                          download
                        </span>{" "}
                        Download
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={(e) => pausedQrCode(e)}
                      >
                        <span className="material-icons me-2 fs-4 text-primary">
                          pause_circle_outline
                        </span>{" "}
                        Pause QR Code
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={(e) => activeQrCode(e)}
                      >
                        <span className="material-icons me-2 fs-4 text-primary">
                        live_tv
                        </span>{" "}
                        Activate QR Code
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={(e) => moveFolder("modal")}
                      >
                        <span className="material-icons me-2 fs-4 text-primary">
                          folder_open
                        </span>{" "}
                        Move Folder
                      </a>
                    </li>
                    {/* <li><a className="dropdown-item"><span className="material-icons me-2 fs-4 text-primary">library_add</span> Duplicate</a></li> */}
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={(e) => multipleDelete()}
                      >
                        <span className="material-icons me-2 fs-4 text-primary">
                          delete_outline
                        </span>{" "}
                        Delete
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="select_dropdown ml-md-2"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Filter{" "}
                  <span className="material-icons ms-2">filter_list</span>
                </div>
                <div
                  className="tabs_icon nav nav-pills"
                  id="pills-tab"
                  role="tablist"
                >
                  <span
                    className={`material-icons nav-link ${activeGrid ? 'active' : ''} list_alt`}
                    onClick={()=>setActiveGrid(true)}
                    id="pills-tab1-tab"
                    data-toggle="pill"
                    data-target="#pills-tab1"
                    type="button"
                    role="tab"
                    aria-controls="pills-tab1"
                    aria-selected="true"
                  >
                    view_stream
                  </span>
                  <span
                    className={`material-icons nav-link ${activeGrid ? '' : 'active'}`}
                    onClick={()=>setActiveGrid(false)}
                    id="pills-profile-tab"
                    data-toggle="pill"
                    data-target="#pills-tab2"
                    type="button"
                    role="tab"
                    aria-controls="pills-tab2"
                    aria-selected="false"
                  >
                    list_alt
                  </span>
                </div>
              </div>
            </div>
            <div className="filter_list collapse" id="collapseExample">
              <div className="filters_row">
                <h1>Filter</h1>
                <div className="text_filter">
                  <h3 onClick={(e) => handleResetFilters()} className="me-3">
                    Reset Filter{" "}
                    <span className="material-icons ms-2">clear_all</span>
                  </h3>
                  <h3
                    onClick={(e) => handleFilters("", "combineFilters")}
                    className="bule_h3"
                  >
                    Apply <span className="material-icons ms-2">done</span>
                  </h3>
                </div>
              </div>
              <div className="row_name">
                <div className="row ">
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label>Campaign Name</label>
                      <input
                        type="text"
                        value={filter?.campaignName}
                        onChange={(e) =>
                          setfilter({ ...filter, campaignName: e.target.value })
                        }
                        className="form-control"
                        placeholder="Campaign Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label>QR Code ID</label>
                      <input
                        type="text"
                        value={filter?.slug}
                        onChange={(e) =>
                          setfilter({ ...filter, slug: e.target.value })
                        }
                        className="form-control"
                        placeholder="QR Code ID"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Type</label>
                    <Select
                      className=""
                      value={filter?.type}
                      onChange={(e) => setfilter({ ...filter, type: e })}
                      options={types}
                      isClearable={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Medium</label>
                    <input
                      type="text"
                      className="form-control"
                      value={filter?.medium}
                      onChange={(e) =>
                        setfilter({ ...filter, medium: e.target.value })
                      }
                      placeholder="e.g. email, banner, bus stop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${activeGrid ? 'show active' : ''}`}
              id="pills-tab1"
              role="tabpanel"
              aria-labelledby="pills-tab1-tab"
            >
              <div className="card_row">
              {data?.length > 0 &&<div className="my-3">
                  <input
                    type="checkbox"
                    checked={checkAllChecks()}
                    onClick={(e) => handleAllCheck(e.target.checked)}
                    className="form-check-input fs-5"
                  />
                  <span className="selectall">Select All</span>
                </div>}

                {data &&
                  data.map((item, index) => {
                    return (
                      <>
                        <div className="card_div mt-3">
                          <div className="list-group-item d-flex justify-content-between listing_fl">
                            <h1 className="name_heading">
                              <div className="form-check">
                                <input
                                  checked={dataCheck.includes(item?.id)}
                                  onClick={(e) =>
                                    handleCheck(e.target.checked, item)
                                  }
                                  type="checkbox"
                                  className="form-check-input"
                                />
                              </div>
                              <span
                                onClick={(e) => handleStatus(item, "status")}
                                className={`mx-2 ${
                                  item?.staticQrcode
                                    ? "static"
                                    : item?.status == "pause"
                                    ? "paused pointer"
                                    : "status pointer"
                                } text-capitalize`}
                              >
                                {item?.staticQrcode
                                  ? "Static"
                                  : item?.status == "pause"
                                  ? "Paused"
                                  : "Active"}
                              </span>

                              {/* {!item?.staticQrcode ? <span onClick={e => handleStatus(item?.slug, 'edit')} className="qrCodeName pointer">
                                                         <span className="blue-text">{item?.setting?.qrCodeName || '--'}</span> </span> : null} */}
                              <span
                                onClick={(e) =>
                                  handleStatus(item?.slug, "edit")
                                }
                                className="qrCodeName pointer"
                              >
                                <span className="blue-text">
                                  {methodModel?.capitalizeFirstLetter(item?.setting?.qrCodeName) || "--"}
                                </span>{" "}
                              </span>
                            </h1>
                            <div className="btns_blue">
                              {!item?.staticQrcode ? (
                                <button
                                  onClick={(e) =>{ 
                                      setExpiry({
                                        ...item,
                                        setting: {
                                          ...item.setting,
                                          numberOfScans:item?.totalScans,
                                        },
                                      })
                                  }}
                                  // disabled={ActivePlan?.name == "free"}
                                  data-bs-toggle="modal"
                                  data-bs-target="#Expirymodal"
                                  className="btn primary_btn ms-2"
                                >
                                  Expiry{" "}
                                  <span className="material-icons ms-2">
                                    date_range
                                  </span>
                                </button>
                              ) : null}
                              {/* <div
                                style={{ width: "250px", height: "250px" }}
                                id={`Multicanvas${index}`}
                                // ref={(element) =>
                                //   (MultiRef.current[index] = element)
                                // }
                              ></div> */}
                              {/* <button className="btn primary_btn ms-2">Retarget <span className="material-icons ms-2">forward</span></button> */}
                              {/* <button className="btn primary_btn ms-2">Share <span className="material-icons ms-2">share</span></button> */}
                              <button
                                onClick={(e) => {
                                  DownloadQR(index, item);
                                  setdownloadimg(item?.image);
                                }}
                                className="btn primary_btn2 ms-2 text-white"
                              >
                                Download QR Code{" "}
                                <span className="material-icons ms-2">
                                  download
                                </span>
                              </button>
                            </div>
                          </div>
                          <div className="list-group-item">
                            <div className="row">
                              <div className="col-md-12 col-lg-6 border-right">
                                <div className="row_22 border_22">
                                  <div className="left_list">
                                    <div className="row_qr">
                                      {!item?.staticQrcode ? (
                                        <div className="layout_qr mb-3">
                                          <span className="material-icons ico me-2">
                                            folder
                                          </span>{" "}
                                          {item?.setting?.folderName || "--"}
                                        </div>
                                      ) : null}
                                      {!item?.staticQrcode ? (
                                        <div className="layout_qr mb-3">
                                          <span className="material-icons ico me-2">
                                            link
                                          </span>
                                          <span
                                            className="pointer link_btn"
                                            onClick={(e) =>
                                              handleRedirectURL(
                                                `${environment?.liveURL}${item?.slug}`
                                              )
                                            }
                                          >{`${environment?.liveURL}${item?.slug}`}</span>
                                        </div>
                                      ) : null}
                                      <div className="layout_qr mb-3">
                                        <span className="material-icons ico  me-2">
                                          send
                                        </span>
                                        {item?.type == "text" ||
                                        item?.type == "email" ||
                                        item?.type == "events" ||
                                        item?.type == "sms" ||
                                        item?.type == "call" ||
                                        item?.type == "vcard" ||
                                        item?.staticQrcode ? (
                                          <span className=" ">
                                            {" "}
                                            {!item?.qrCode_data ? (
                                              `${environment?.liveURL}${item?.slug}`
                                            ) : item?.type == "text" ? (
                                              <span className="ellipes">
                                                {item?.message}
                                              </span>
                                            ) : (
                                              <span className="ellipes">
                                                {item?.qrCode_data}
                                              </span>
                                            )}
                                          </span>
                                        ) : (
                                          <span
                                            className="link_btn pointer"
                                            onClick={(e) =>
                                              handleRedirectURL(
                                                !item?.qrCode_data
                                                  ? `${environment?.liveURL}${item?.slug}`
                                                  : item?.qrCode_data
                                              )
                                            }
                                          >
                                            {" "}
                                            {!item?.qrCode_data ? (
                                              `${environment?.liveURL}${item?.slug}`
                                            ) : item?.type == "text" ? (
                                              <span className="ellipes">
                                                {item?.message}
                                              </span>
                                            ) : (
                                              <span className="ellipes">
                                                {item?.qrCode_data}
                                              </span>
                                            )}
                                          </span>
                                        )}
                                      </div>
                                      <div className="list_rowqr">
                                        <div className="layout_qr">
                                          Created:{" "}
                                          <b>
                                            {item?.createdAt
                                              ? moment(item?.createdAt).format(
                                                  "MMM DD, YYYY"
                                                ) +
                                                " " +
                                                moment(item?.createdAt).format(
                                                  "hh:mm a"
                                                )
                                              : "--"}
                                          </b>
                                        </div>
                                        <div className="layout_qr text-capitalize">
                                          Type: <b>{FindType(item?.type)}</b>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {!item?.staticQrcode ? (
                                    <div className="border_leftclass">
                                      <div className="box_col2 mb-2">
                                        <p className="mb-2">Unique Scans</p>
                                        <h3 className="mb-0">
                                          {item?.slug_details?.length || 0}
                                        </h3>
                                      </div>
                                      <div className="box_col2 box_col3">
                                        <p className="mb-2">Total Scans</p>
                                        <h3 className="mb-0">
                                          {totalScansCount(item?.slug_details)}
                                        </h3>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-6">
                                <div className="row_qrcode">
                                  {!item?.staticQrcode ? (
                                    <div className="">
                                      <div className="list_data mb-3">
                                        <p className="p_row1">Medium</p>
                                        <p
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                          onClick={(e) => setEditData(item)}
                                          className="qrCodeName pointer"
                                        >
                                          <span className="blue-text">
                                            {item?.setting?.flyer || "--"}
                                          </span>{" "}
                                        </p>{" "}
                                        {/* <p className="p_row12 text-capitalize">{item?.setting?.flyer || '--'}</p> */}
                                      </div>
                                      <div className="list_data mb-3">
                                        <p className="p_row1">Print Run</p>
                                        <span
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                          onClick={(e) => setEditData(item)}
                                          className="qrCodeName pointer"
                                        >
                                          <span className="blue-text">
                                            {item?.setting?.printRun || "--"}
                                          </span>{" "}
                                        </span>
                                      </div>
                                      <div className="list_data mb-3">
                                        <p className="p_row1">Campaign Start</p>
                                        <span
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                          onClick={(e) => setEditData(item)}
                                          className="qrCodeName pointer"
                                        >
                                          <span className="blue-text">
                                            {item?.setting?.campaignStart
                                              ? moment(
                                                  item?.setting?.campaignStart
                                                ).format("MMM DD, YYYY")
                                              : "--"}
                                          </span>{" "}
                                        </span>
                                      </div>
                                      <div className="list_data">
                                        <p className="p_row1">Campaign End</p>
                                        <span
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                          onClick={(e) => setEditData(item)}
                                          className="qrCodeName pointer"
                                        >
                                          <span className="blue-text">
                                            {item?.setting?.campaignEnd
                                              ? moment(
                                                  item?.setting?.campaignEnd
                                                ).format("MMM DD, YYYY")
                                              : "--"}
                                          </span>{" "}
                                        </span>
                                        {/* <p className="p_row12">Set Date <span className="material-icons ms-2">insert_invitation</span></p> */}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="list_data"> </div>
                                  )}
                                  {/* <div style={{ padding: '5px'  }} ref={ref => {
                                                                canvasRef.current[index] = ref
                                                            }} className="qr_img">
                                                                <img src={item?.image} style={{ width: '100%', height: '100%' }}></img>
                                                            </div> */}
                                  {/* {item?.design?.isFrame ? ( */}
                                  <div
                                    style={{ padding: "5px" }}
                                    ref={(ref) => {
                                      canvasRef.current[index] = ref;
                                    }}
                                    className="qr_img"
                                  >
                                    <img
                                      src={item?.image}
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                        width: "auto",
                                        height: "auto",
                                      }}
                                    ></img>
                                  </div>
                                  {/* ) : (
                                    <div
                                      style={{ padding: "5px" }}
                                      id={`Multicanvas${index}`}
                                      className="qr_img"
                                    ></div>
                                  )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div
              className={`tab-pane fade ${activeGrid ? "" : "show active"}`}
              id="pills-tab2"
              role="tabpanel"
              aria-labelledby="pills-tab2-tab"
            >
              <div className="table-responsive ">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span className="table_th">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              checked={checkAllChecks()}
                              onClick={(e) => handleAllCheck(e.target.checked)}
                              type="checkbox"
                            />
                          </div>
                          Campaign Name
                        </span>
                      </th>
                      <th scope="col">
                        <span className="table_th">QR Code ID</span>
                      </th>
                      <th scope="col">
                        <span className="table_th">Type</span>
                      </th>
                      {/* <th scope="col"><span className="table_th">Flyer</span></th>
                                        <th scope="col"><span className="table_th">Print Run</span></th> */}
                      <th scope="col">
                        <span className="table_th">Campaign Start</span>
                      </th>
                      <th scope="col">
                        <span className="table_th">Campaign End</span>
                      </th>
                      <th scope="col">
                        <span className="table_th">Unique Scans</span>
                      </th>
                      <th scope="col">
                        <span className="table_th">Total Scans</span>
                      </th>
                      <th scope="col">
                        <span className="table_th">Status</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <span className="table_td">
                                <div className="form-check">
                                  <input
                                    checked={dataCheck.includes(item?.id)}
                                    onClick={(e) =>
                                      handleCheck(e.target.checked, item)
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                                <span
                                  onClick={(e) =>
                                    handleStatus(item?.slug, "edit")
                                  }
                                  className="campaign-name pointer"
                                >
                                  <span className="blue-text">
                                    {" "}
                                    {item?.setting?.qrCodeName || "--"}{" "}
                                  </span>
                                </span>{" "}
                              </span>
                            </td>

                            <td>{item?.slug || "--"}</td>
                            <td className="text-capitalize">
                              {FindType(item?.type)}
                            </td>
                            {/* <td className="text-capitalize">{item?.setting?.flyer || '--'}</td> */}
                            {/* <td>{item?.setting?.printRun || '--'}</td> */}

                            <td>
                              {item?.setting?.campaignStart
                                ? moment(item?.setting?.campaignStart).format(
                                    "MMM DD,YYYY"
                                  )
                                : "--"}
                            </td>
                            <td>
                              {item?.setting?.campaignEnd
                                ? moment(item?.setting?.campaignEnd).format(
                                    "MMM DD,YYYY"
                                  )
                                : "--"}
                            </td>
                            <td>
                              {!item?.staticQrcode
                                ? item?.slug_details?.length || 0
                                : "--"}
                            </td>
                            <td>
                              {!item?.staticQrcode
                                ? totalScansCount(item?.slug_details)
                                : "--"}
                            </td>
                            <td>
                              <div className="div_active d-flex justify-content-between">
                                <span
                                  onClick={(e) => handleStatus(item, "status")}
                                  className={`mx-2 ${
                                    item?.staticQrcode
                                      ? "static"
                                      : item?.status == "pause"
                                      ? "paused pointer"
                                      : "status pointer"
                                  } text-capitalize`}
                                >
                                  {item?.staticQrcode
                                    ? "Static"
                                    : item?.status == "pause"
                                    ? "Paused"
                                    : "Active"}
                                </span>
                                {/* <i className="fa fa-pen text-primary" onClick={e => handleStatus(item?.slug, 'edit')}></i>  */}
                                <div className="dropdown pointer">
                                  <span
                                    className="material-icons delete_bar"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    more_horiz
                                  </span>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          DownloadQR(index, item);
                                          setdownloadimg(item?.image);
                                        }}
                                      >
                                        <span className="material-icons me-2 text-primary fs-4">
                                          download
                                        </span>{" "}
                                        Download
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={(e) =>
                                          setrename({
                                            active: true,
                                            id: item?.id,
                                            ...item,
                                          })
                                        }
                                      >
                                        <span className="material-icons drop_icon me-2">
                                          title
                                        </span>{" "}
                                        Rename
                                      </a>
                                    </li>
                                    {!item?.staticQrcode ? (
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={(e) =>
                                            handlePausedQrCode(item?.id)
                                          }
                                        >
                                          <span className="material-icons me-2 fs-4 text-primary">
                                            pause_circle_outline
                                          </span>{" "}
                                          Pause QR Code
                                        </a>
                                      </li>
                                    ) : null}
                                    {!item?.staticQrcode ? (
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={(e) =>
                                            handleActivateQrCode(item?.id)
                                          }
                                        >
                                          <span className="material-icons me-2 fs-4 text-primary">
                                            live_tv
                                          </span>{" "}
                                          Activate QR Code
                                        </a>
                                      </li>
                                    ) : null}
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={(e) =>
                                          moveFolder("modal", item?.id)
                                        }
                                      >
                                        <span className="material-icons me-2 fs-4 text-primary">
                                          folder_open
                                        </span>{" "}
                                        Move Folder
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={(e) =>
                                          handleStatus(item?.id, "delete")
                                        }
                                      >
                                        <span className="material-icons me-2 fs-4 text-primary">
                                          delete_outline
                                        </span>{" "}
                                        Delete
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {filter?.count < total ? (
              <div className="d-flex justify-content-between align-items-center ms-2">
                <div>
                  <b>{total}</b> Campaigns
                </div>
                <div className="d-flex justify-content-center">
                  <Pagination
                    activePage={filter?.page}
                    itemsCountPerPage={filter?.count}
                    totalItemsCount={total}
                    pageRangeDisplayed={1}
                    onChange={handlePagination}
                    hideFirstLastPages={true}
                  />
                </div>
                <div></div>
              </div>
            ) : null}
          </div>
        </div>

        {/* Move Folder Modal */}
        <button
          type="button"
          id="OpenMoveFolderModal"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#moveFolderModal"
        >
          Move Folder
        </button>
        <div
          className="modal fade"
          id="moveFolderModal"
          tabindex="-1"
          aria-labelledby="moveFolderModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="moveFolderModalLabel">
                  Move Folder
                </h1>
                <button
                  type="button"
                  id="CloseMoveFolderModal"
                  onClick={(e) =>{history('/app/folder');  document.getElementById("OpenMoveFolderModal").click()}}
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Add Folder
                </button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label>Select Folder</label>
                    <select
                      className="form-select"
                      value={selectedFolder?.id}
                      onChange={(e) => handleFolderSelect(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value="">Select Folder</option>
                      {folder &&
                        folder.map((item, index) => {
                          return (
                            <option value={item?._id}>{item?.name}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseMoveFolderModal"
                  onClick={(e) => setdataCheck([])}
                  className="btn btn-clear"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={!selectedFolder?.id}
                  onClick={(e) => moveFolder("Save")}
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Select format Modal */}
        <button
          type="button"
          id="OpenFormatModel"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#FormatQrImageModal"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="FormatQrImageModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Download Format
                </h1>
              </div>
              <div className="modal-body">
                <div className="d-flex justify-content-center gap-5 my-2">
                {/* <div><p className={`svg_box pointer ${selectFormat == 'svg' ? 'download_active' : ''}`} onClick={e => setSelectedFormat('svg')}>SVG</p></div> */}
                <div>
                  <p
                    className={`png_box pointer ${
                      selectFormat == "png" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("png")}
                  >
                    PNG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      selectFormat == "jpeg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("jpeg")}
                  >
                    JPEG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      selectFormat == "svg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("svg")}
                  >
                    SVG
                  </p>
                </div>
                </div>
                <div className="img_qr">
                <img ref={canvasRef2} src={downloadimg} alt="" />
</div>
                {/* <div><p className={`webp_box pointer ${selectFormat == 'webp' ? 'download_active' : ''}`} onClick={e => setselectFormat('webp')}>WEBP</p></div> */}
              </div>

              {/* <div style={{width:"100%"}} id="Multicanvas2" className="ms-5"></div> */}

              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseFormatModal"
                  onClick={(e) => setSelectedFormat("")}
                  className="btn btn-clear"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${
                    !selectFormat ? "disable" : ""
                  }`}
                  disabled={!selectFormat}
                  onClick={convertHTMLToImage}
                >
                  Confirm <i className="fa fa-check text-light ms-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* FORMAT MODAL FOR MULTI-QR CODE DOWNLOAD */}
        <button
          type="button"
          id="OpenMultiFormatModel"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#FormatMultiQrImageModal"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="FormatMultiQrImageModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Download Format
                </h1>
              </div>
              <div className="modal-body d-flex justify-content-center gap-5 my-2">
                {/* <div><p className={`svg_box pointer ${selectFormat == 'svg' ? 'download_active' : ''}`} onClick={e => setSelectedFormat('svg')}>SVG</p></div> */}
                <div>
                  <p
                    className={`png_box pointer ${
                      selectFormat == "png" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("png")}
                  >
                    PNG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      selectFormat == "jpeg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("jpeg")}
                  >
                    JPEG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      selectFormat == "svg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("svg")}
                  >
                    SVG
                  </p>
                </div>
                {/* <div><p className={`webp_box pointer ${selectFormat == 'webp' ? 'download_active' : ''}`} onClick={e => setselectFormat('webp')}>WEBP</p></div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseMultiFormatModal"
                  onClick={(e) => setSelectedFormat("")}
                  className="btn btn-clear"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${
                    !selectFormat ? "disable" : ""
                  }`}
                  disabled={!selectFormat}
                  onClick={DownLoadMutliQR}
                >
                  Confirm <i className="fa fa-check text-light ms-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <button
          type="button"
          id="Tablemodal"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#TablemodalOpen"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="TablemodalOpen"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Download Format
                </h1>
              </div>
              <div className="modal-body d-flex justify-content-center gap-5 my-2">
                {/* <div><p className={`svg_box pointer ${selectFormat == 'svg' ? 'download_active' : ''}`} onClick={e => setSelectedFormat('svg')}>SVG</p></div> */}
                <div>
                  <p
                    className={`png_box pointer ${
                      Tableformat == "png" ? "download_active" : ""
                    }`}
                    onClick={(e) => setTableformat("png")}
                  >
                    PNG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      Tableformat == "jpeg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setTableformat("jpeg")}
                  >
                    JPEG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      Tableformat == "svg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setTableformat("svg")}
                  >
                    SVG
                  </p>
                </div>
                {/* <div><p className={`webp_box pointer ${selectFormat == 'webp' ? 'download_active' : ''}`} onClick={e => setselectFormat('webp')}>WEBP</p></div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseTableFormatModal"
                  onClick={(e) => setTableformat("")}
                  className="btn btn-clear"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${!Tableformat ? "disable" : ""}`}
                  disabled={!Tableformat}
                  onClick={() => {
                    handleDownloadQrCode();
                  }}
                >
                  Confirm <i className="fa fa-check text-light ms-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Campaign Modal */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Campaign
                </h5>
              </div>
              <form onSubmit={(e) => UpdateEditdata(e)}>
                <div class="">
                  <div className="">
                    <div className="row">
                      <div className="">
                        <div className="">
                          <div className="">
                            <p className="my-2 ms-3">Medium</p>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                name="flyer"
                                type="text"
                                placeholder="e.g. email, banner, bus stop"
                                onChange={(e) => HandleEditchange(e)}
                                value={EditData?.setting?.flyer}
                                required
                              />
                            </div>
                            <p className="my-2 ms-3">Print Run</p>
                            <div className="col-md-12">
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                name="printRun"
                                placeholder=" e.g. 500"
                                onChange={(e) => HandleEditchange(e)}
                                value={EditData?.setting?.printRun}
                              />
                            </div>
                            <p className="my-2 ms-3">Campaign Start</p>
                            <div className="col-md-12">
                              <input
                                type="date"
                                className="form-control"
                                onChange={(e) => HandleEditchange(e)}
                                name="campaignStart"
                                value={moment(
                                  EditData?.setting?.campaignStart
                                ).format("YYYY-MM-DD")}
                              />
                            </div>

                            <label className="my-2 ms-3">Campaign End</label>
                            <div className="col-md-12">
                              <input
                                type="date"
                                className="form-control"
                                onChange={(e) => HandleEditchange(e)}
                                name="campaignEnd"
                                value={moment(
                                  EditData?.setting?.campaignEnd
                                ).format("YYYY-MM-DD")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer mt-1">
                  <button
                    type="button"
                    class="btn btn-clear border"
                    id="btnclose"
                    data-bs-dismiss="modal"
                  >
                    Back
                  </button>
                  <button type="submit" class="btn btn-primary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Expiry Bootstrap modal */}
        <button
          type="button"
          class="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Launch demo modal
        </button>

        <div
          class="modal fade"
          id="Expirymodal"
          data-bs-backdrop="static"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog expiry_modal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Expiry
                </h5>
              </div>
              <div class="modal-body">
                <p>
                  You can set the QR Code to expire after a number of scans or
                  by date. This is useful for scenarios like limiting the number
                  of voucher redemptions.
                </p>
                <label> Number of scans</label>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-check form-switch switch_button">
                      <input
                        checked={Expiry?.setting?.numberOfScansStatus}
                        onChange={(e) =>
                          setExpiry({
                            ...Expiry,
                            setting: {
                              ...Expiry.setting,
                              numberOfScansStatus: e.target.checked,
                            },
                          })
                        }
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="numberOfScans"
                      />
                      <label
                        className="form-check-label label_text"
                        htmlFor="numberOfScans"
                      >
                        Enabled
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      type="text"
                      value={Expiry?.setting?.numberOfScans
                          ? Expiry?.setting?.numberOfScans
                          : ""
                      }
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          setting: {
                            ...Expiry.setting,
                            numberOfScans: e.target.value,
                          },
                        })
                      }
                      disabled={!Expiry?.setting?.numberOfScansStatus}
                      maxLength="5"
                      className="form-control"
                      placeholder="Number of scans"
                    />
                  </div>
                </div>
                <label>Date Range</label>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-check form-switch switch_button">
                      <input
                        checked={Expiry?.setting?.dateRangeStatus}
                        onChange={(e) =>
                          setExpiry({
                            ...Expiry,
                            setting: {
                              ...Expiry.setting,
                              dateRangeStatus: e.target.checked,
                            },
                          })
                        }
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="dateRange"
                      />
                      <label
                        className="form-check-label label_text"
                        htmlFor="dateRange"
                      >
                        Enabled
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      value={moment(Expiry?.setting?.dateRangeStart).format(
                        "YYYY-MM-DD"
                      )}
                      name="dateRangeStart"
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          setting: {
                            ...Expiry.setting,
                            dateRangeStart: e.target.value,
                          },
                        })
                      }
                      className="form-control"
                      type="date"
                      placeholderText="Start"
                      disabled={!Expiry?.setting?.dateRangeStatus}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      value={moment(Expiry?.setting?.dateRangeEnd).format(
                        "YYYY-MM-DD"
                      )}
                      name="dateRangeEnd"
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          setting: {
                            ...Expiry.setting,
                            dateRangeEnd: e.target.value,
                          },
                        })
                      }
                      className="form-control"
                      type="date"
                      placeholderText="Start"
                      disabled={!Expiry?.setting?.dateRangeStatus}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label>Expiry URL</label>
                    <input
                      type="text"
                      value={Expiry?.setting?.expiryURL}
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          setting: {
                            ...Expiry.setting,
                            expiryURL: e.target.value,
                          },
                        })
                      }
                      disabled={
                        !Expiry?.setting?.numberOfScansStatus &&
                        !Expiry?.setting?.dateRangeStatus
                      }
                      className="form-control"
                      placeholder="https://"
                    />
                    <p className="mt-1">
                      Visitors will be redirected to this URL after the main
                      link expires.
                    </p>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-clear border"
                  id="CloseExpiry"
                  data-bs-dismiss="modal"
                >
                  Back
                </button>
                <button
                  onClick={(e) => UpdateExpiry(e)}
                  type="button"
                  class="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        {data.length === 0 ? <h2 className="no_data text-center">No Data</h2> : ""}
      </Layout>
    </>
  );
};

export default QrCodeListing;
